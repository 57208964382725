import React from 'react';
import { GatsbySeo as SEO } from 'gatsby-plugin-next-seo';

import Layout from '@components/Layout/Layout';

const NotFoundPage = () => (
  <Layout>
    <SEO noindex={true} />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
